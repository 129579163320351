<template>
	<div>
	<!-- <v-app> -->
		<v-container>
      <v-row dense>
        <h3 class='text-h5 grey--text text--darken-2'>構造物一覧</h3>
      </v-row>
      <br>

      <v-row>
        <v-col><v-spacer></v-spacer></v-col>
      </v-row>
      <v-row>
        <v-col><v-spacer></v-spacer></v-col>
      </v-row>

      <v-row dense justify="start" align="center">
        <v-dialog v-model="dialog" persistent max-width="800px">
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-button"
                plain
                v-bind="attrs"
                v-on="on"
              >
              <v-icon color="indigo darken-2">mdi-format-columns</v-icon>
                表示項目
              </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">表示項目設定</span>
            </v-card-title>
            <v-card-text>
            <v-container fluid>
              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="構造物種類"
                    color="green"
                    value="構造物種類"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="構造物名"
                    color="green"
                    value="構造物名"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="路線名"
                    color="green"
                    value="路線名"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="所在地"
                    color="green"
                    value="所在地"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="管理者名"
                    color="green"
                    value="管理者名"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="対象部材"
                    color="orange"
                    value="対象部材"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="架設年次"
                    color="orange"
                    value="架設年次"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="交通量(台/12h)"
                    color="orange"
                    value="交通量(台/12h)"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="大型車混入率(%)"
                    color="orange"
                    value="大型車混入率(%)"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="橋長(m)"
                    color="orange"
                    value="橋長(m)"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="幅員(m)"
                    color="orange"
                    value="幅員(m)"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="桁本数"
                    color="orange"
                    value="桁本数"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="材料区分(RC・PC)"
                    color="orange"
                    value="材料区分(RC・PC)"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="路下条件"
                    color="orange"
                    value="路下条件"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="代替路の有無"
                    color="orange"
                    value="代替路の有無"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="自専道or一般道"
                    color="orange"
                    value="自専道or一般道"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="緊急輸送道路"
                    color="orange"
                    value="緊急輸送道路"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="占用物件(名称)"
                    color="orange"
                    value="占用物件(名称)"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="桁形式区分"
                    color="orange"
                    value="桁形式区分"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="等級"
                    color="orange"
                    value="等級"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="有効幅員(m)"
                    color="orange"
                    value="有効幅員(m)"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="橋面積(m2)"
                    color="orange"
                    value="橋面積(m2)"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="総径間数"
                    color="orange"
                    value="総径間数"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="平面形状"
                    color="orange"
                    value="平面形状"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="平面線形(m)"
                    color="orange"
                    value="平面線形(m)"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="縦断勾配(%)"
                    color="orange"
                    value="縦断勾配(%)"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="設計基準"
                    color="orange"
                    value="設計基準"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="設計活荷重"
                    color="orange"
                    value="設計活荷重"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="設計震度"
                    color="orange"
                    value="設計震度"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="主桁材料区分"
                    color="orange"
                    value="主桁材料区分"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="上部工構造形式"
                    color="orange"
                    value="上部工構造形式"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="路面位置"
                    color="orange"
                    value="路面位置"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="床版材料区分"
                    color="orange"
                    value="床版材料区分"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="床版厚(mm)"
                    color="orange"
                    value="床版厚(mm)"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="床版形式"
                    color="orange"
                    value="床版形式"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="防水工有無"
                    color="orange"
                    value="防水工有無"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="材料区分"
                    color="orange"
                    value="材料区分"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="下部構造高(m)"
                    color="orange"
                    value="下部構造高(m)"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="下部構造形式"
                    color="orange"
                    value="下部構造形式"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="排水施設"
                    color="orange"
                    value="排水施設"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="遮音壁の有無"
                    color="orange"
                    value="遮音壁の有無"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="高欄種別"
                    color="orange"
                    value="高欄種別"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="緯度"
                    color="blue"
                    value="緯度"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="経度"
                    color="blue"
                    value="経度"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="主損傷"
                    color="blue"
                    value="主損傷"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="他損傷1"
                    color="blue"
                    value="他損傷1"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="他損傷2"
                    color="blue"
                    value="他損傷2"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="主原因"
                    color="blue"
                    value="主原因"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="他原因1"
                    color="blue"
                    value="他原因1"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="他原因2"
                    color="blue"
                    value="他原因2"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="補修工法種別候補1"
                    color="blue"
                    value="補修工法種別候補1"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="補修工法種別候補2"
                    color="blue"
                    value="補修工法種別候補2"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="補修工法種別候補3"
                    color="blue"
                    value="補修工法種別候補3"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="一般補修工法名主"
                    color="blue"
                    value="一般補修工法名主"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="一般補修工法名従1"
                    color="blue"
                    value="一般補修工法名従1"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="一般補修工法名従2"
                    color="blue"
                    value="一般補修工法名従2"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="離岸距離"
                    color="blue"
                    value="離岸距離"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="年降水量"
                    color="blue"
                    value="年降水量"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="ASR地質"
                    color="blue"
                    value="ASR地質"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="0°C以下日"
                    color="blue"
                    value="0°C以下日"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="画像名"
                    color="blue"
                    value="画像名"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="損傷原因の調査必要有無"
                    color="blue"
                    value="損傷原因の調査必要有無"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="補修工法種別候補の調査必要有無"
                    color="blue"
                    value="補修工法種別候補の調査必要有無"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <!-- <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="一般補修工法の調査必要有無"
                    color="blue"
                    value="一般補修工法の調査必要有無"
                    hide-details
                  ></v-checkbox>
                </v-col> -->
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="損傷原因の判定元"
                    color="blue"
                    value="損傷原因の判定元"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="補修工法種別候補の判定元"
                    color="blue"
                    value="補修工法種別候補の判定元"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <!-- <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="一般補修工法名の判定元"
                    color="blue"
                    value="一般補修工法名の判定元"
                    hide-details
                  ></v-checkbox>
                </v-col> -->
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="画像撮影日時"
                    color="red"
                    value="画像撮影日時"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="データ更新日時"
                    color="red"
                    value="データ更新日時"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="ex4"
                    label="ユーザー名"
                    color="red"
                    value="ユーザー名"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

            </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                閉じる
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="setDisplayHeader"
              >
                保存
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="setDisplayHeaderDefault"
              >
                デフォルトに戻す
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
          v-if="!$store.state.isSelectMode"
          v-bind:disabled="isProcessing"
          class='text-button pl-1'
          @click="getList"
          plain
        >
          <v-icon color="indigo darken-2">mdi-refresh</v-icon>
          更新
        </v-btn>

        <v-btn
          v-if="!$store.state.isSelectMode"
          v-bind:disabled="isProcessing || items.length == 0"
          class='text-button ml-3'
          dark
          color="indigo darken-2"
          @click="download"
        >
          <v-icon class="">mdi-download</v-icon>
          ダウンロード
        </v-btn>

        <v-btn
          v-if="$store.state.isAdmin && !$store.state.isSelectMode"
          v-bind:disabled="isProcessing || items.length == 0"
          class='text-button ml-6'
          dark
          color="indigo darken-2"
          @click="downloadAll"
        >
          <v-icon>mdi-download</v-icon>
          ダウンロード(全データ)
        </v-btn>
        
        <v-divider vertical class='pl-6'></v-divider>

        <v-btn
          v-if="!$store.state.isSelectMode"
          v-bind:disabled="isProcessing || items.length == 0"
          class='text-button'
          @click="selectDelete(true)"
          plain
        >
          <v-icon color="indigo darken-2">mdi-format-list-checkbox</v-icon>
          削除選択
        </v-btn>

        <v-btn
          v-if="$store.state.isSelectMode"
          class='text-button font-weight-bold'
          @click="selectDelete(false)"
          color="indigo darken-2"
          plain
        >
          キャンセル
        </v-btn>

        <v-dialog
          v-if="$store.state.isSelectMode"
          v-model="showDeleteDiaglog"
          persistent
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              v-bind="attrs"
              class='text-button font-weight-bold'
              plain
              v-on="on"
              v-bind:disabled="$store.state.deleteItems.length==0"
            >
            <v-icon color="red darken-2">mdi-trash-can</v-icon>
              選択したデータを削除
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              データ削除
            </v-card-title>
            <v-card-text class="font-weight-bold">選択したデータ({{$store.state.deleteItems.length}}件)を削除しますか？</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="showDeleteDiaglog = false"
                depressed
              >
                キャンセル
              </v-btn>
              <v-btn
                color="error"
                depressed
                @click="runDelete"
                class="font-weight-bold"
              >
                削除
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <span
          v-if="$store.state.isSelectMode"
          class="grey--text text--darken-2 caption"
        >
          {{$store.state.deleteItems.length}}件選択中
        </span>

      </v-row>

      <v-row dense>
        <v-col><v-spacer></v-spacer></v-col>
      </v-row>
      
			<v-divider></v-divider>
      <br>
<!--  -->
      
<!--  -->
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table v-if="$store.state.isSelectMode"
          v-model="$store.state.deleteItems"
          :headers="headers"
          :items="items"
          :single-select="singleSelect"
          :options.sync="options"
          :footer-props="{
                    'items-per-page-options': [10, 15, 20, 50, 100, 200, 300, 400, 500],
                    showFirstLastPage: true,
                  }"
          :search="search"
          :sort-by="$store.state.sortedHeader"
          :sort-desc="$store.state.sortedDesc"
          item-key="id"
          show-select
          class="elevation-1"
          @click:row="rowClicked"
          @update:sort-by="sortClicked"
          @update:sort-desc="sortDescUpdated"
          @toggle-select-all="itemSelectedAll"
        >
        </v-data-table>
        <v-data-table v-else
          v-model="selected"
          :headers="headers"
          :items="items"
          :single-select="singleSelect"
          :options.sync="options"
          :footer-props="{
                    'items-per-page-options': [10, 15, 20, 50, 100, 200, 300, 400, 500],
                    showFirstLastPage: true,
                  }"
          :search="search"
          :sort-by="$store.state.sortedHeader"
          :sort-desc="$store.state.sortedDesc"
          item-key="id userId"
          class="elevation-1"
          @click:row="rowClicked"
          @update:sort-by="sortClicked"
          @update:sort-desc="sortDescUpdated"
        >

        <!-- <template v-slot:header.checkBox="{ }">
          <v-checkbox></v-checkbox>
        </template>

        <template v-slot:item.checkBox="{ item }">
          <v-checkbox
          v-model="item.checkBox"
          v-bind:disabled="$store.state.account['localAccountId'] != item.userId"
          >
          </v-checkbox>
        </template> -->

        </v-data-table>
      </v-card>

      <v-overlay :value="isShowIndicator">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

    </v-container>
      <router-view />
	<!-- </v-app> -->
	</div>
</template>

<script>
  import iconv from 'iconv-lite'

  export default {
    methods: {
      // itemSelected() {
      //   console.log(this.$store.state.deleteItems);
      //   console.log(this.$store.state.deleteItems.length)
      // },
      itemSelectedAll() {
        this.search = ''
        const ids = this.$store.state.deleteItems.map( i => i['id'])
        console.log(ids.length)
        for ( const i of this.items) {
          if ( !ids.includes(i['id']) ) {
            this.$store.state.deleteItems.push(i)
          }
        }
        if (this.$store.state.deleteItems.length <= ids.length) {
          this.$store.dispatch("updateDeleteItems", []);
        }
      },
      selectDelete(bool) {
        // this.$store.dispatch("changeMode", !this.$store.state.isSelectMode);
        this.$store.dispatch("changeMode", bool);
        if (this.$store.state.isSelectMode) {
          const account = this.$store.state.account['localAccountId']
          this.items = this.items.filter( function(c) { return account==c['userId']} )
          this.$store.dispatch("setCurrentDataList", this.items);
        } else {
          this.items = this.$store.state.currentDataLlistDefault
          this.$store.dispatch("setCurrentDataList", this.items);
          this.$store.dispatch("updateDeleteItems", []);
        }
      },
      async runDelete() {
        this.isShowIndicator = true
        this.showDeleteDiaglog = false
        const ids = this.$store.state.deleteItems.map( i => i['id'])
        
        await this.getAccessToken(this.$store.state.account)
        const data = { 
          ids : ids
        };
        await this.$axios.post('/delete', data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.accessToken
          }
          })
          .then ( () => {
            this.$store.dispatch("setSnackbar", "削除しました");
            this.getList();
            this.$store.dispatch("changeMode", false);
            this.$store.dispatch("updateDeleteItems", []);
            // this.$store.state.gotListState = 0;
            // this.back()
            this.isShowIndicator = false
          })
          .catch ( err => {
            console.log(err);
            this.isShowIndicator = false
          })
      },

      sortClicked(item) {
        if (item) {
          if (item != this.$store.state.sortedHeader) {
            this.$store.dispatch("setCurrentSortDesc", false);
          }
          this.$store.dispatch("setCurrentSortHeader", item);
        } else {
          this.$store.dispatch("setCurrentSortHeader", '');
        }
      },
      sortDescUpdated(item) {
        if (item) {
          this.$store.dispatch("setCurrentSortDesc", item);
        }
      },
      rowClicked(item) {
        this.$store.dispatch("setCurrentData", item);
        this.$router.push({name: 'buildingdetail'})
      },
      async download() {
        await this.getAccessToken(this.$store.state.account)
        const data = {data : false}
        await this.$axios.post('/download_list', data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.accessToken
          }
        })
          .then( response =>{
            const message = response.data['message'];
            const jsonstring = JSON.parse(message);
            var header = Object.keys(jsonstring[0]).join(',');
            var header_converted = []
            for (const hd of header.split(',')) {
              header_converted.push(this.colnamesRev[hd])
            }
            header_converted = header_converted.join(',') + "\n";
            
            var body = jsonstring.map(function(d){
                return Object.keys(d).map(function(key) {
                    return d[key];
                }).join(',');
            }).join("\n");
            const csvstring =  header_converted + body;

            let data = JSON.stringify({
              csv: csvstring
            })
            // TODO: Webkitかどうか判定できるようにしなければ
            try {
              window.webkit.messageHandlers.download.postMessage(data)
            } catch {
              console.log("not webkit")
            }

            const shiftjis = iconv.encode(csvstring, "Shift_JIS")
            // var blob = new Blob(
            //         [csvstring],
            //         { "type": "text/csv;charset=utf-8;" })
            var blob = new Blob(
                    [shiftjis],
                    { "type": "text/csv;" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = '構造物一覧.csv'
            link.click()
          })
          .catch(err => {
            console.log(err);
          });
      },
      async downloadAll() {
        await this.getAccessToken(this.$store.state.account)
        const data = {data : this.$store.state.isAdmin}
        await this.$axios.post('/download_list', data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.accessToken
          }
        })
          .then( response =>{
            const message = response.data['message'];
            const jsonstring = JSON.parse(message);
            var header = Object.keys(jsonstring[0]).join(',');
            var header_converted = []
            for (const hd of header.split(',')) {
              header_converted.push(this.colnamesRev[hd])
            }
            header_converted = header_converted.join(',') + "\n";
            
            var body = jsonstring.map(function(d){
                return Object.keys(d).map(function(key) {
                    return d[key];
                }).join(',');
            }).join("\n");
            const csvstring =  header_converted + body;
            
            let data = JSON.stringify({
              csv: csvstring
            })
            // TODO: Webkitかどうか判定できるようにしなければ
            try {
              window.webkit.messageHandlers.downloadAll.postMessage(data)
            } catch {
              console.log("not webkit")
            }

            const shiftjis = iconv.encode(csvstring, "Shift_JIS")
            var blob = new Blob(
                    [shiftjis],
                    { "type": "text/csv;" })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = '構造物一覧.csv'
            link.click()
          })
          .catch(err => {
            console.log(err);
          });
      },
      async getList() {
        await this.getAccessToken(this.$store.state.account)
        this.isShowIndicator = true
        const data = {data : this.$store.state.isAdmin}
        await this.$axios.post('/list', data, {
          headers: {
            Authorization: "Bearer " + this.$store.state.accessToken
          }
          })
          .then( response =>{
            const message = response.data['message']
            let json = JSON.parse(message)
            for ( let i = 0; i < json.length; i++ ) {
              // json[i]['checkBox'] = false
              let imagedate = json[i]["imagedate"];
              if (imagedate != null) {
                let date = new Date(imagedate)
                // json[i]["imagedate"] = date.toLocaleString()
                json[i]["imagedate"] = date.toLocaleString("ja-JP", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'})
              }

              let update = json[i]["dataupdated"];
              if (update != null) {
                let date = new Date(update)                
                // json[i]["dataupdated"] = date.toLocaleString()
                json[i]["dataupdated"] = date.toLocaleString("ja-JP", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'})
              }
            }
            this.$store.dispatch("setCurrentDataList", json);
            this.$store.dispatch("setCurrentDefaultDataList", json);
            this.items = this.$store.state.currentDataLlist
            // console.log(typeof(this.items))
            // console.log(this.items[0]['userId'])
            this.isShowIndicator = false
          })
          .catch( err => {
            console.log(err)
            this.isShowIndicator = false
          });
      },
      setDisplayHeader() {
        // this.headers = [{ text: 'checkBox', sortable: false, value: 'checkBox' }]
        this.headers = []
        for ( const item of this.ex4) {
          this.headers.push({ text: item, value: this.colnames[item] })
        }
        this.$store.dispatch("setCurrentHeaders", this.headers)
        this.$store.dispatch("setCurrentCols", this.ex4)
        this.dialog = false
      },
      setDisplayHeaderDefault() {
        this.ex4 = ['構造物種類',	'構造物名',	'路線名',	'所在地',	'管理者名', '対象部材', 'データ更新日時']
        // this.headers = [{ text: 'checkBox', sortable: false, value: 'checkBox' }]
        this.headers = []
        for ( const item of this.ex4) {
          this.headers.push({ text: item, value: this.colnames[item] })
        }
        this.$store.dispatch("setCurrentHeaders", this.headers)
        this.$store.dispatch("setCurrentCols", this.ex4)
        this.dialog = false
      }
    },
    created : function() {
      // console.log("created : function()")

      if (this.$store.state.gotListState == 0) {
        // this.selectDelete(false)
        this.$store.dispatch("changeMode", false);
        this.$store.dispatch("updateDeleteItems", []);
        this.getList()

        this.$store.dispatch("setCurrentGotListState", 1);
      } else {
        this.items = this.$store.state.currentDataLlist
      }
      this.headers = this.$store.state.headers
      this.ex4 = this.$store.state.cols
    },
    data: () => ({
      search: '',
      isProcessing: false,
      isShowIndicator: false,
      dialog: false,
      ex4: ['構造物種類',	'構造物名',	'路線名',	'所在地',	'管理者名', '対象部材', 'データ更新日時'],
      selected: [],
      singleSelect: false,
      // selectMode: false,
      showDeleteDiaglog: false,
      colnames: {
        '構造物種類':'kouzoubutsusyurui',
        '構造物名':'kouzoubutsumei',
        '路線名':'rosenmei',
        '所在地':'syozaichi',
        '管理者名':'kanrisyamei',
        '対象部材':'hosyuutaisyoubuzai',
        '架設年次':'kasetsunenzi',
        '交通量(台/12h)':'koutsuuryou',
        '大型車混入率(%)':'oogatasyakonnyuuritsu',
        '橋長(m)':'kyouchou',
        '幅員(m)':'hukuin',
        '桁本数':'ketahonsuu',
        '材料区分(RC・PC)':'zairyoukubunrcpc',
        '路下条件':'rokazyouken',
        '代替路の有無':'daitaironoumu',
        '自専道or一般道':'zidousyadouippandou',
        '緊急輸送道路':'kinnkyuuyusoudouro',
        '占用物件(名称)':'senyuubukken',
        '桁形式区分':'ketakeishikikubun',
        '等級':'toukyuu',
        '有効幅員(m)':'yuukouhukuin',
        '橋面積(m2)':'hashimenseki',
        '総径間数':'soukeikansuu',
        '平面形状':'heimenkeizyou',
        '平面線形(m)':'heimensenkei',
        '縦断勾配(%)':'zyuudankoubai',
        '設計基準':'sekkeikizyun',
        '設計活荷重':'sekkeikatsukazyuu',
        '設計震度':'sekkeishindo',
        '主桁材料区分':'syuketazairyoukubun',
        '上部工構造形式':'zyoubukoukouzoukeishiki',
        '路面位置':'romenichi',
        '床版材料区分':'syoubanzairyoukubun',
        '床版厚(mm)':'syoubanatsu',
        '床版形式':'syoubankeishiki',
        '防水工有無':'bousuikouumu',
        '材料区分':'zairyoukubun',
        '下部構造高(m)':'kabukouzoudaka',
        '下部構造形式':'kabukouzoukeishiki',
        '排水施設':'haisuishisetsu',
        '遮音壁の有無':'syaonhekinoumu',
        '高欄種別':'kouransyubetsu',
        '緯度':'ido',
        '経度':'keido',
        '主損傷':'sonsyousyurui',
        '他損傷1':'sonsyousyuruiz1',
        '他損傷2':'sonsyousyuruiz2',
        '主原因':'sonsyougenin',
        '他原因1':'sonsyougeninz1',
        '他原因2':'sonsyougeninz2',
        '補修工法種別候補1':'taisakukouhousyubetsu',
        '補修工法種別候補2':'taisakukouhousyubetsuz1',
        '補修工法種別候補3':'taisakukouhousyubetsuz2',
        '一般補修工法名主':'ippanhosyuukouhou',
        '一般補修工法名従1':'ippanhosyuukouhouz1',
        '一般補修工法名従2':'ippanhosyuukouhouz2',
        '離岸距離':'rigankyori',
        '年降水量':'nenkousuiryou',
        'ASR地質':'asr',
        '0°C以下日':'zerodoika',
        '画像名':'imagename',
        '損傷原因の調査必要有無':'damagecheck',
        '損傷原因の判定元':'sonsyouorigin',
        '補修工法種別候補の判定元':'taisakuorigin',
        '一般補修工法名の判定元':'ippanorigin',
        'ユーザー名':'userName',
        '補修工法種別候補の調査必要有無':'taisakucheck',
        '一般補修工法の調査必要有無':'ippancheck',
        '画像撮影日時':'imagedate',
        'データ更新日時':'dataupdated'
      },
      colnamesRev: {
        'kouzoubutsusyurui':'構造物種類',
        'kouzoubutsumei':'構造物名',
        'rosenmei':'路線名',
        'syozaichi':'所在地',
        'kanrisyamei':'管理者名',
        'hosyuutaisyoubuzai':'対象部材',
        'kasetsunenzi':'架設年次',
        'koutsuuryou':'交通量(台/12h)',
        'oogatasyakonnyuuritsu':'大型車混入率(%)',
        'kyouchou':'橋長(m)',
        'hukuin':'幅員(m)',
        'ketahonsuu':'桁本数',
        'zairyoukubunrcpc':'材料区分(RC・PC)',
        'rokazyouken':'路下条件',
        'daitaironoumu':'代替路の有無',
        'zidousyadouippandou':'自専道or一般道',
        'kinnkyuuyusoudouro':'緊急輸送道路',
        'senyuubukken':'占用物件(名称)',
        'ketakeishikikubun':'桁形式区分',
        'toukyuu':'等級',
        'yuukouhukuin':'有効幅員(m)',
        'hashimenseki':'橋面積(m2)',
        'soukeikansuu':'総径間数',
        'heimenkeizyou':'平面形状',
        'heimensenkei':'平面線形(m)',
        'zyuudankoubai':'縦断勾配(%)',
        'sekkeikizyun':'設計基準',
        'sekkeikatsukazyuu':'設計活荷重',
        'sekkeishindo':'設計震度',
        'syuketazairyoukubun':'主桁材料区分',
        'zyoubukoukouzoukeishiki':'上部工構造形式',
        'romenichi':'路面位置',
        'syoubanzairyoukubun':'床版材料区分',
        'syoubanatsu':'床版厚(mm)',
        'syoubankeishiki':'床版形式',
        'bousuikouumu':'防水工有無',
        'zairyoukubun':'材料区分',
        'kabukouzoudaka':'下部構造高(m)',
        'kabukouzoukeishiki':'下部構造形式',
        'haisuishisetsu':'排水施設',
        'syaonhekinoumu':'遮音壁の有無',
        'kouransyubetsu':'高欄種別',
        'ido':'緯度',
        'keido':'経度',
        'sonsyousyurui':'主損傷',
        'sonsyousyuruiz1':'他損傷1',
        'sonsyousyuruiz2':'他損傷2',
        'sonsyougenin':'主原因',
        'sonsyougeninz1':'他原因1',
        'sonsyougeninz2':'他原因2',
        'taisakukouhousyubetsu':'補修工法種別候補1',
        'taisakukouhousyubetsuz1':'補修工法種別候補2',
        'taisakukouhousyubetsuz2':'補修工法種別候補3',
        'ippanhosyuukouhou':'一般補修工法名主',
        'ippanhosyuukouhouz1':'一般補修工法名従1',
        'ippanhosyuukouhouz2':'一般補修工法名従2',
        'rigankyori':'離岸距離',
        'nenkousuiryou':'年降水量',
        'asr':'ASR地質',
        'zerodoika':'0°C以下日',
        'imagename':'画像名',
        'damagecheck':'損傷原因の調査必要有無',
        'sonsyouorigin':'損傷原因の判定元',
        'taisakuorigin':'補修工法種別候補の判定元',
        'ippanorigin':'一般補修工法名の判定元',
        'userName':'ユーザー名',
        'taisakucheck':'補修工法種別候補の調査必要有無',
        'ippancheck':'一般補修工法の調査必要有無',
        'imagedate':'画像撮影日時',
        'dataupdated':'データ更新日時'
      },
      options: {
        page: 1,
        itemsPerPage: 50,
        // sortBy: ['form_id'],
        // sortDesc: [true]
      },
      items: [{},],
      headers: [],
      store: false
    }),
  }
</script>